const config = {
  url: location.protocol + '//' + location.host + "/jade/",
  // url: "http://asus.doudouzhu.cn:24520/jade/",
  codeURL: location.protocol + '//' + location.host,
  // title: '伟琦立'
  // title: '万乘钱呈'
  // title: '科莱琪'
  // title: '天皓利融'
  title: ''
}

export default config
