import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/layoutBar.vue'
import config from '@/config/app'

const routes = [
  {
		path: '/',
		component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/Home.vue'),
        meta: {
          title: "我的报告",
          isFooter: true
        }
      },
      {
        path: 'report-add',
        component: () => import('@/views/reportAdd/reportAdd.vue'),
        meta: {
          title: "新增",
          isFooter: false,
          isBack: true
        }
      },
    ]
  },
  {
    path: '/prview',
    component: () => import('@/views/prview/prview.vue'),
    meta: {
      title: "详情",
      isFooter: false,
      isBack: true
    }
  },
  {
    path: '/launch',
    component: () => import('@/views/launch/index.vue'),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: "登录"
    }
  }
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || config.title
	next()
})

export default router;
