import axios from "axios";
import { getToken, getValueByKeys } from "./utils";
import { closeToast, showLoadingToast, showToast } from "vant";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  baseURL: '/',
  timeout: 60000
});

http.interceptors.request.use((config) => {
  showLoadingToast({
    message: '加载中...',
    forbidClick: true
  })
  const token = getToken()
  if (token) {
    config.headers['Token'] = token
  }
  return config
})

http.interceptors.response.use(response => {
  closeToast()
  if (response.data.code === 0) {
    if (response.config.url.indexOf('/user/login') > -1) {
      localStorage.setItem('scope_url', response.headers['scope_url'])
    }
    return response
  }

  showToast(response.data.msg)
  if (response.data.code === 401) {
    store.dispatch('user/logout').then(() => {
      showToast('请在我的里面进行登录')
      router.replace('/home')
    })
  }

  return Promise.reject(new Error(response.data.msg || 'Error'))
}, (error) => {
  closeToast()
  const status = getValueByKeys(error, "response.status", 500);
  const httpCodeLabel = {
    400: "请求参数错误",
    401: "未授权，请登录",
    403: "拒绝访问",
    404: `请求地址出错: ${getValueByKeys(error, "response.config.url", "")}`,
    408: "请求超时",
    500: "API接口报500错误",
    501: "服务未实现",
    502: "网关错误",
    503: "服务不可用",
    504: "网关超时",
    505: "HTTP版本不受支持"
  };
  if (error && error.response) {
    console.error("请求错误", error.response.data);
  }
  showToast(httpCodeLabel[status] || "接口错误")

  if (status === 401) {
    store.dispatch('user/logout').then(() => {
      showToast('请在我的里面进行登录')
      router.replace('/home')
    })
  }

  return Promise.reject(new Error(httpCodeLabel[status] || "接口错误"));
})

// export default http;
export default (o) => {
  return new Promise((resolve, reject) => {
    http(o)
      .then((res) => {
        return resolve(res.data);
      })
      .catch(reject);
  });
};
