import { getUserInfo, userLogin, userLogout } from "@/api/login";
import { getToken, setToken } from "@/utils/utils";

const state = {
  token: getToken(),
  user: JSON.parse(localStorage.getItem('user') || null),
  avatar: '',
  name: '',
  qrcode: localStorage.getItem('qrcode') || '',
  codeId: localStorage.getItem('codeId') || 0
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_USER: (state, user) => {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  SET_QRCODE: (state, qrcode) => {
    state.qrcode = qrcode
    localStorage.setItem('qrcode', qrcode)
  },
  SET_CODE_ID: (state, codeId) => {
    state.codeId = codeId
    localStorage.setItem('codeId', codeId)
  }
}

const actions = {
  async login({ commit }, loginForm) {
    return new Promise((resolve) => {
      userLogin(loginForm).then(res => {
        commit('SET_TOKEN', res.data.token)
        location.href = localStorage.getItem('scope_url')
        getUserInfo({}).then(resp => {
          commit('SET_USER', resp.data.user)
          commit('SET_QRCODE', resp.data.qrcode)
          location.href = localStorage.getItem('scope_url')
          resolve()
        })
      }).catch(err => {
        console.log(err)
      })
    })
    
  },
   // user logout
   logout({ commit }) {
    return new Promise((resolve) => {
      userLogout().then(() => {
        commit('SET_TOKEN', '')
        commit('SET_USER', null)
        commit('SET_QRCODE', '')
        localStorage.clear()
        resolve()
      })
    }).catch(err => {
      console.log(err)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
