const TOKEN = 'TOKEN'

export const setToken = (str) => {
  localStorage.setItem(TOKEN, str)
}

export const getToken = () => {
  const token = localStorage.getItem(TOKEN) ? localStorage.getItem(TOKEN) : ''
  return token
}

export const removeToken = () => {
  localStorage.removeItem(TOKEN)
}

/**
 * 获取对象下的字段值
 * @param record {}
 * @param key 'a.b.c'
 * @param defaultValue
 * @returns
 */
export const getValueByKeys = (record, key, defaultValue) => {
  const keys = key.split(".");
  for (let i = 0; i < keys.length; i++) {
    record = record[keys[i]] || (i === keys.length - 1 ? defaultValue : {});
  }
  return record || defaultValue;
};

export const formatData = (str, type) => {
	const _d = new Date(str)
	const y = _d.getFullYear()
	const M = (_d.getMonth() + 1) < 10 ? '0' + (_d.getMonth() + 1) : (_d.getMonth() + 1)
	const d = _d.getDate() < 10 ? '0' + _d.getDate() : _d.getDate()
  const H = _d.getHours() < 10 ? '0' + _d.getHours() : _d.getHours()
  const m = _d.getMinutes() < 10 ? '0' + _d.getMinutes(0) : _d.getMinutes()
  const s = _d.getSeconds() < 10 ? '0' + _d.getSeconds() : _d.getSeconds()
  if (!type) {
    return `${y}-${M}-${d} ${H}:${m}:${s}`
  } else {
    return `${y}-${M}-${d}`
  }
}
