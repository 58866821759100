import http from '@/utils/http'

// 登录
export const userLogin = (data) => {
  return http({
    method: 'post',
    url: "/api/user/login",
    data
  })
}

// 注册
export const userRegister = (data) => {
  return http({
    method: 'post',
    url: "/api/user/register",
    data
  })
}

// 登出
export const userLogout = (data) => {
  return http({
    method: 'post',
    url: "/api/user/logout",
    data
  })
}

export const wxloginurl = () => {
  return http({
    method: 'post',
    url: "/api/user/wxloginurl"
  })
}

export const wxlogin = (openid) => {
  return http({
    method: 'post',
    url: "/api/user/wxlogin?openid=" + openid
  })
}
