<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },

}
</script>

<style lang="less">
@import './assets/css/utils.less';

body {
  height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, PingFang SC, DIN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
  background-color: #F6F4F2;
  height: 100%;
}
</style>
