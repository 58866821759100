
const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  user: state => state.user.user,
  qrcode: state => state.user.qrcode,
  codeId: state => state.user.codeId
}
export default getters
