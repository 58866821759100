<template>
	<div class="layout">
		<van-nav-bar :title="route.meta?.title ?? config.title" :right-text="route.meta.edit" :left-text="route.meta.isBack ? '返回' : ''" :left-arrow="route.meta.isBack" @click-left="onClickLeft" @click-right="onClickRight"/>
		<div class="content" :style="{ '--height': route.meta.isFooter ? 'calc(100vh - 100px)' : 'calc(100vh - 46px)' }">
			<router-view></router-view>
		</div>
		<van-tabbar v-show="route.meta.isFooter" route active-color="#A7A196" @change="onChange">
			<van-tabbar-item replace to="/home" icon="home-o">
				<span>我的报告</span>
				<template #icon="props">
					<img :src="props.active ? require('@/assets/tabbar/home_active.png') : require('@/assets/tabbar/home.png')" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item name="code" icon="bag-o">
				<span>条形码</span>
				<template #icon="props">
					<img :src="props.active ? require('@/assets/tabbar/qg_active.png') : require('@/assets/tabbar/qg.png')" />
				</template>
			</van-tabbar-item>
			<!-- <van-tabbar-item replace to="/mine" icon="friends-o">
				<span>我的</span>
				<template #icon="props">
					<img :src="props.active ? require('@/assets/tabbar/mine_active.png') : require('@/assets/tabbar/mine.png')" />
				</template>
			</van-tabbar-item> -->
		</van-tabbar>
		<van-popup v-model:show="showCenter" :style="{ padding: '64px' }">
			<div>
				<canvas ref="barcode"></canvas>
			</div>
		</van-popup>
	</div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import config from '@/config/app'
import { nextTick, ref } from 'vue';
import JsBarcode from 'jsbarcode';
import store from '@/store';

const route = useRoute()
const router = useRouter()
const showCenter = ref(false)
const barcode = ref()
const info = store.getters.user

// 点击导航左侧按钮
const onClickLeft = () => {
	if (!window.history.state.back) {
		router.replace('/home')
	} else {
		router.go(-1)
	}
}

const onChange = (name) => {
	if (name === 'code') {
		showCenter.value = true;
		nextTick(() => {
			JsBarcode(barcode.value, info.id)
		})
	}
	console.log(name)
}

// 点击导航右侧按钮
const onClickRight = () => {
	router.push('/businessCardDetails')
}

</script>

<style lang="less" scoped>
::v-deep .van-popup--center {
	padding: 0;
	background: transparent !important;
}
.layout {
	overflow: hidden;

	.content {
		height: var(--height);
		overflow: auto;
	}
}
</style>
